<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1 h1--white">Come around!</h1>
        <p class="p p--white introb1__p">
          Wir freuen uns, Sie in unserer Brasserie begrüßen zu dürfen. Gerne
          reservieren wir Ihnen einen Tisch in unserem großen Gastraum. Am
          besten ganz einfach online reservieren oder Sie rufen uns kurz an und
          wir finden den richtigen Platz für Sie. Bei Reservierungsanfragen ab 5
          Personen kontaktieren Sie uns bitte per E-Mail und beachten Sie bitte,
          dass in diesem Fall nur Menüs angeboten werden können. Wir freuen uns
          auf Sie!
          <br /><br />
          Welcome to our Brasserie. We love to reserve a table for you in our
          dining area. We take phone as well as online reservations. Please
          note, if you are a party of 5 or more please contact us via Email
          because a set menu is mandatory. We are looking forward to having you
          with us.
        </p>

        <div class="introb1__boxbutton">
          <AppButton
            class="introb1__btn"
            :link="'https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiRXNHM3V0ZVJtVEs1RlJhUjIiLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9'"
          >
            Online Reservieren
          </AppButton>
        </div>
        <div class="introb1__logo">
          <TheLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
export default {
  components: { TheLogo, AppButton },
};
</script>

<style lang="scss">
.introb1__reservmodule {
  background-color: $main-color-3;
}
</style>
